<template>
  <div class="about fade-in-top">
    <div class="text-info">
      <h2>{{ $t("about.title") }}</h2>
      <p>{{ $t("about.firstParagraph") }}</p>
      <p>{{ $t("about.secondParagraph") }}</p>
      <section>
        <p>{{ $t("about.list.title") }}</p>
        <ul>
          <li>{{ $t("about.list.list1") }}</li>
          <li>{{ $t("about.list.list2") }}</li>
          <li>{{ $t("about.list.list3") }}</li>
          <li>{{ $t("about.list.list4") }}</li>
          <li>{{ $t("about.list.list5") }}</li>
          <li>{{ $t("about.list.list6") }}</li>
          <li>{{ $t("about.list.list7") }}</li>
        </ul>
      </section>

      <br>

      <h2>{{ $t("about1.title") }}</h2>
      <section>
        <p>{{ $t("about1.list.title") }}</p>
        <ul>
          <li>{{ $t("about1.list.list1") }}</li>
          <li>{{ $t("about1.list.list2") }}</li>
          <li>{{ $t("about1.list.list3") }}</li>
          <li>{{ $t("about1.list.list4") }}</li>
          <li>{{ $t("about1.list.list5") }}</li>
          <li>{{ $t("about1.list.list6") }}</li>
          <li>{{ $t("about1.list.list7") }}</li>
          <li>{{ $t("about1.list.list8") }}</li>
          <li>{{ $t("about1.list.list9") }}</li>
          <li>{{ $t("about1.list.list10") }}</li>
          <li>{{ $t("about1.list.list11") }}</li>
          <li>{{ $t("about1.list.list12") }}</li>
          <li>{{ $t("about1.list.list13") }}</li>
          <li>{{ $t("about1.list.list14") }}</li>
          <li>{{ $t("about1.list.list15") }}</li>
          <li>{{ $t("about1.list.list16") }}</li>
          <li>{{ $t("about1.list.list17") }}</li>
          <li>{{ $t("about1.list.list18") }}</li>
          <li>{{ $t("about1.list.list19") }}</li>
          <li>{{ $t("about1.list.list20") }}</li>
        </ul>
      </section>

      <br>

      <h2>{{ $t("about2.title") }}</h2>
      <p>{{ $t("about2.firstParagraph") }}</p>
      <p>{{ $t("about2.secondParagraph") }}</p>

      <br>

      <h2>{{ $t("about3.title") }}</h2>
      <p>{{ $t("about3.firstParagraph") }}</p>
      <p>{{ $t("about3.secondParagraph") }}</p>
      <p>{{ $t("about3.thirdParagraph") }}</p>
      <p>{{ $t("about3.fourthParagraph") }}</p>
      <p>{{ $t("about3.fifthParagraph") }}</p>

      <br>

      <h2>{{ $t("about4.title") }}</h2>
      <p>{{ $t("about4.firstParagraph") }}</p>
      <p><b>{{ $t("about4.secondParagraphTitle") }}</b> {{ $t("about4.secondParagraph") }}</p>

    </div>
  </div>
</template>

<style scoped lang="scss">
  .about {
    margin-top: 100px;
    p {
      margin-bottom: 0;
    }
    ul {
      margin-top: 0;
    }
  }
</style>